<template>
	<div
		class="checkbox"
		:class="[size, classes]"
		:tabindex="disabled ? -1 : 0"
		@keyup.tab="keyboardFocus = true"
		@focusout="keyboardFocus = false"
		@keydown.space.prevent="handleInput"
		@keydown.enter.prevent="handleInput"
	>
		<label
			class="checkbox--container"
			:class="{ flip }"
		>
			<!-- @slot Slot to replace default tick checkmark with custom markup or svg -->
			<!-- @bindings {boolean} isChecked, disabled {String} size -->
			<slot
				name="checkbox"
				v-bind="{ isChecked, disabled, size, keyboardFocus }"
			>
				<div class="checkbox--boundingbox">
					<input
						type="checkbox"
						:class="{ error }"
						:name="name"
						:value="value"
						:checked="isChecked"
						:disabled="disabled"
						tabindex="-1"
						@change="handleInput"
					/>
					<span class="checkbox--tick">
						<svg
							width="31"
							height="22"
							viewBox="0 0 31 22"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M29.6096 1.12842C30.1843 1.70312 30.1843 2.6349 29.6096 3.2096L11.9501 20.8691C11.3754 21.4438 10.4436 21.4438 9.86891 20.8691L1.03918 12.0393C0.464478 11.4646 0.464478 10.5328 1.03918 9.95815C1.61389 9.38344 2.54567 9.38344 3.12037 9.95815L10.9095 17.7473L27.5284 1.12842C28.1031 0.553712 29.0349 0.553712 29.6096 1.12842Z"
							/>
						</svg>
					</span>
				</div>
			</slot>

			<!-- @slot Slot for custom label markup -->
			<!-- @binding {string} label, {boolean} isChecked, disabled -->
			<slot
				name="label"
				v-bind="{ label, isChecked, disabled }"
			>
				<span
					v-if="label"
					class="checkbox--label"
					:class="`font-text-${size || 'm'}`"
					>{{ label }}</span
				>
			</slot>
		</label>
	</div>
</template>

<script>
import sizes from "./definitions/sizes";

/**
 * TnCheckbox component provides users the ability to choose between multiple distinct
 * values/options. Typical use cases include complex forms, checklists, filtering or terms and conditions.
 * @displayName TnCheckbox
 */
export default defineComponent({
	name: "TnCheckbox",

	props: {
		/**
		 * Specifies the name of the checkbox for forms
		 */
		name: {
			type: String,
			default: "",
		},
		/**
		 * The string to use as the checkbox value when submitting the form, etc
		 */
		value: {
			type: [Boolean, String, Number],
			default: "",
		},
		/**
		 * The label/text displayed at the right-hand side of the checkbox. @see <slots> for options for customizing all markup
		 */
		label: {
			type: String,
			default: "",
		},
		/**
		 * Disables clicking/toggling the checkbox
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * modelValue value(s)
		 */
		modelValue: {
			type: [Array, Boolean],
			default: () => false,
		},
		/**
		 * Sets the size for the checkbox
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.includes(value.toLowerCase()) || "m";
			},
		},
		/**
		 * Flips the order of the checkmark and label
		 */
		flip: {
			type: Boolean,
			default: false,
		},
		/**
		 * Error/invalid state. Use to validate
		 */
		error: {
			type: Boolean,
			default: false,
		},
		/**
		 * Apply dark theming
		 */
		dark: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			keyboardFocus: false,
		};
	},

	computed: {
		isChecked() {
			return typeof this.modelValue === "boolean" ? this.modelValue : this.modelValue.includes(this.value);
		},
		classes() {
			return {
				"checkbox--disabled": this.disabled,
				"checkbox--error": this.error,
				"checkbox--dark": this.dark,
				"checkbox--focus": !this.disabled && this.keyboardFocus,
			};
		},
	},

	methods: {
		handleInput() {
			if (typeof this.modelValue === "boolean") {
				this.$emit("update:modelValue", !this.modelValue);
			} else {
				let modelValue = [...this.modelValue];
				modelValue.includes(this.value)
					? (modelValue = modelValue.filter((value) => value !== this.value))
					: modelValue.push(this.value);
				this.$emit("update:modelValue", modelValue);
			}
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;
@use "@/assets/typography/css/typography.css";
@use "sass:color";

@function hexToRGB($hex) {
	@return color.channel($hex, "red", $space: rgb), color.channel($hex, "green", $space: rgb),
		color.channel($hex, "blue", $space: rgb);
}

.checkbox {
	--checkbox-text-color: #{variables.$color-primary-dark};
	--checkbox-bg-color: #{variables.$color-neutrals-white};
	--checkbox-bg-checked-color: #{variables.$color-primary-mid};
	--checkbox-bg-checked-hover-color: #{variables.$color-cta-hover};
	--checkbox-bg-hover: rgba(#{hexToRGB(variables.$color-cta-hover-background)}, 0.08);
	--checkbox-border-color: #{variables.$color-cta-default};
	--checkbox-active-color: #{variables.$color-cta-active};
	--checkbox-focus-color: #{variables.$color-cta-focus};
	--checkbox-disabled-color: #{variables.$color-cta-disabled};
	--checkbox-tick-color: #{variables.$color-cta-default};
	--checkbox-tick-color-checked: #{variables.$color-neutrals-white};

	outline: none;

	* {
		margin: 0;
		padding: 0;
		cursor: pointer;
	}

	&--tick {
		opacity: 0;
		position: absolute;

		svg {
			width: 11.94px;
			height: 8.21px;
		}

		path {
			fill: var(--checkbox-tick-color);
		}
	}

	input[type="checkbox"] {
		width: 20px;
		height: 20px;
		appearance: none;
		background-color: var(--checkbox-bg-color);
		border-color: var(--checkbox-border-color);
		border-radius: 2px;
		border-style: solid;
		border-width: 1.5px;
		box-sizing: border-box;
		cursor: pointer;
		flex-shrink: 0;

		&:checked {
			--checkbox-bg-color: var(--checkbox-bg-checked-color);
			--checkbox-bg-hover: var(--checkbox-bg-checked-hover-color);

			~ .checkbox--tick {
				--checkbox-tick-color: var(--checkbox-tick-color-checked);

				opacity: 1;
			}
		}

		&:disabled {
			--checkbox-border-color: var(--checkbox-disabled-color);

			&:checked {
				--checkbox-bg-color: var(--checkbox-disabled-color);
			}
		}
	}

	&--boundingbox {
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
	}

	&--container {
		display: flex;
		align-items: center;
		min-height: 44px;

		&:hover {
			input[type="checkbox"]:not(:disabled) {
				background-color: var(--checkbox-bg-hover);
				transform: scale(1.1, 1.1);

				~ .checkbox--tick {
					opacity: 0.3;
				}

				&:checked {
					border-color: var(--checkbox-bg-checked-hover-color);

					~ .checkbox--tick {
						opacity: 1;
					}
				}
			}
		}

		&:active {
			input[type="checkbox"]:not(:disabled) {
				background-color: var(--checkbox-active-color);
				border-color: var(--checkbox-active-color);
			}
		}

		&.flip :last-child {
			order: -1;
		}
	}

	&--label {
		margin-left: 8px;
		color: var(--checkbox-text-color);
	}

	&--error {
		--checkbox-bg-checked-color: #{variables.$color-critical-500-core};
		--checkbox-bg-checked-hover-color: #{variables.$color-critical-500-core};
		--checkbox-border-color: #{variables.$color-critical-500-core};
		--checkbox-bg-hover: rgba(#{hexToRGB(variables.$color-critical-500-core)}, 0.08);
		--checkbox-active-color: #{variables.$color-critical-500-core};

		input[type="checkbox"]:checked {
			--checkbox-bg-hover: #{variables.$color-critical-500-core};
		}
	}

	&--disabled {
		* {
			cursor: not-allowed;
		}
	}

	&--focus {
		input[type="checkbox"] {
			outline: 2px solid var(--checkbox-focus-color);
			outline-offset: 3px;
		}
	}

	&--dark {
		--checkbox-text-color: #{variables.$color-cta-dark-default};
		--checkbox-bg-color: transparent;
		--checkbox-bg-hover: rgba(#{hexToRGB(variables.$color-cta-dark-hover-background)}, 0.24);
		--checkbox-bg-checked-color: #{variables.$color-cta-dark-default};
		--checkbox-bg-checked-hover-color: #{variables.$color-cta-dark-hover};
		--checkbox-border-color: #{variables.$color-cta-dark-default};
		--checkbox-active-color: #{variables.$color-cta-dark-active};
		--checkbox-focus-color: #{variables.$color-cta-dark-focus};
		--checkbox-disabled-color: #{variables.$color-cta-dark-disabled};
		--checkbox-tick-color: #{variables.$color-primary-dark};
		--checkbox-tick-color-checked: #{variables.$color-primary-dark};

		&.checkbox--error {
			--checkbox-bg-checked-color: #{variables.$color-critical-500-core};
			--checkbox-bg-checked-hover-color: #{variables.$color-critical-500-core};
			--checkbox-border-color: #{variables.$color-critical-500-core};
			--checkbox-bg-hover: rgba(#{hexToRGB(variables.$color-critical-500-core)}, 0.24);
			--checkbox-active-color: #{variables.$color-critical-500-core};
			--checkbox-tick-color: #{variables.$color-neutrals-white};
			--checkbox-tick-color-checked: #{variables.$color-neutrals-white};
		}

		&.checkbox--disabled {
			--checkbox-bg-color: #{variables.$color-neutrals-white};
		}
	}
}
</style>
